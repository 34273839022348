.about {
  background-color: white;
  padding-left: 16rem;
  padding-right: 16rem;
}

.about p {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 2rem;
}
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7rem;
}
.languages {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.resume img {
  height: 250px;
  width: 300px;
}
@media only screen and (max-width: 480px) {
  .about {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .about p {
    font-size: 12px;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .resume img {
    width: 200px;
    height: 200px;
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .about {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .about p {
    font-size: 12px;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .resume img {
    width: 200px;
    height: 200px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .about {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .about p {
    font-size: 12px;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}
